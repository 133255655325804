import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";

function CommentModal({ alertId, alertLevel }) {
  const [modal, setModal] = useState(false);
  const [comment, setComment] = useState("");

  const toggle = () => setModal(!modal);

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleSubmit = () => {
    // Call your API here with the alertId and comment
    // Example: axios.post('/api/comment', { alertId, comment })
    // Close the modal
    toggle();
  };

  return (
    <div>
      {/* <Button color="success" onClick={toggle}>
        Handle
      </Button> */}

      <div
        style={{
          background: "#ffffff",
          border: alertLevel === 1 ? "2px solid #F1E0A7" : "2px solid #DFA4A9", // Slightly darker border color
          borderRadius: "4px", // Rounded corners
          width: "40px", // Width of the box, adjust as needed
          height: "40px", // Height of the box, adjust as needed
          display: "flex",
          justifyContent: "center", // Center the icon horizontally
          alignItems: "center", // Center the icon vertically
        }}
      >
        <i
          className="ri-check-double-line label-icon"
          style={{
            color: alertLevel === 1 ? "#F1E0A7" : "#dc3545",
            fontSize: "24px",
          }}
          onClick={toggle}
        ></i>
      </div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Submit Comment</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="comment">Comment</Label>
              <Input
                type="textarea"
                name="comment"
                id="comment"
                value={comment}
                onChange={handleCommentChange}
              />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            color=""
            style={{ background: "#0B9F82", color: "white" }}
            onClick={handleSubmit}
          >
            Submit
          </Button>{" "}
          <Button
            color=""
            style={{ background: "#343749", color: "white" }}
            onClick={toggle}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default CommentModal;
