import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import getChartColorsArray from "../../Components/Common/ChartsDynamicColor";
const EmsSensorChartsNew = (props) => {
  const { dataColors } = props;
  var linechartBasicColors = getChartColorsArray(dataColors);
  const { sensorHistoryLogs } = props;
  const [temperatureData, setTemperatureData] = useState([]);
  const [humidityData, setHumidityData] = useState([]);
  const [pressureData, setPressureData] = useState([]);

  console.log(sensorHistoryLogs);

  useEffect(() => {
    const interval = 30 * 60 * 1000; // 30 minutes in milliseconds
    const now = new Date();
    now.setMinutes(0, 0, 0); // Round down to the nearest hour
    const twentyFourHoursAgo = new Date(now.getTime() - 24 * 60 * 60 * 1000); // 24 hours ago

    let lastTimestamp = twentyFourHoursAgo.getTime();
    const tempData = [];
    const humData = [];
    const presData = [];

    let tempTime = 0;

    sensorHistoryLogs.forEach((log) => {
      const timestamp = new Date(log.timestamp).getTime();

      console.log("Timestamp Gap:", timestamp - lastTimestamp >= interval);

      // Include data if within the last 24 hours and at least 30 minutes from the last added point
      // if (
      //   timestamp >= twentyFourHoursAgo.getTime() &&
      //   timestamp - lastTimestamp >= interval
      // ) {
      lastTimestamp = timestamp;
      tempTime = timestamp;
      tempData.push({ x: new Date(log.timestamp), y: log.temperature.value });
      humData.push({ x: new Date(log.timestamp), y: log.humidity.value });
      presData.push({
        x: new Date(log.timestamp),
        y: log.differential_pressure.value,
      });
      // }
    });
    // let currentTime = twentyFourHoursAgo.getTime();

    // while (currentTime <= now.getTime() && sensorHistoryLogs.length > 0) {
    //   let closestLog = sensorHistoryLogs.reduce((prev, curr) => {
    //     return Math.abs(curr.timestamp - currentTime) <
    //       Math.abs(prev.timestamp - currentTime)
    //       ? curr
    //       : prev;
    //   });

    //   // Only add data points that are within the 24-hour window
    //   if (
    //     new Date(closestLog.timestamp).getTime() >= twentyFourHoursAgo.getTime()
    //   ) {
    //     tempData.push({
    //       x: new Date(closestLog.timestamp),
    //       y: closestLog.temperature.value,
    //     });
    //     humData.push({
    //       x: new Date(closestLog.timestamp),
    //       y: closestLog.humidity.value,
    //     });
    //     presData.push({
    //       x: new Date(closestLog.timestamp),
    //       y: closestLog.differential_pressure.value,
    //     });
    //   }

    //   currentTime += interval; // Move to the next 30-minute mark
    // }

    setTemperatureData(tempData);
    setHumidityData(humData);
    setPressureData(presData);
    console.log("Temperature Data:", temperatureData);
    console.log("Humidity Data:", humidityData);
    console.log("Pressure Data:", pressureData);
  }, [sensorHistoryLogs]);

  const tempOptions = {
    chart: {
      id: "temperature-chart",
      type: "line",
      height: 350,
      toolbar: {
        show: true,
      },
    },
    markers: {
      size: 1,
      hover: {
        sizeOffset: 3,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
    },
    colors: linechartBasicColors,
    // title: {
    //   text: "Temperature over Time",
    //   align: "left",
    // },
    xaxis: {
      type: "datetime",
      title: {
        text: "Time",
      },
      labels: {
        datetimeFormatter: {
          hour: "HH:mm",
        },
      },
      min: new Date().setHours(0, 0, 0, 0), // start of the current day
      max: new Date().setHours(23, 59, 59, 999), // end of the current day
      tickAmount: 48, // for 30-minute intervals // 24 hours * 2 (for 30-minute intervals)
      range: 24 * 60 * 60 * 1000, // 24 hours in milliseconds
    },
    yaxis: {
      title: {
        text: "Temperature (°C)",
      },
      min: 0, // You might want to adjust this based on your data
      max: 50, // Same as above, adjust according to your data range
    },
    tooltip: {
      x: {
        format: "dd MMM yyyy HH:mm",
      },
    },
    dataLabels: {
      enabled: false,
    },
  };

  const tempSeries = [
    {
      name: "Temperature",
      data: temperatureData, // Assuming this comes from your state or props
    },
  ];

  const humOptions = {
    chart: {
      id: "humidity-chart",
      type: "line",
      height: 350,
      toolbar: {
        show: true,
      },
    },
    markers: {
      size: 4,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
    },
    colors: linechartBasicColors,
    // title: {
    //   text: "Humidity over Time",
    //   align: "left",
    // },
    xaxis: {
      type: "datetime",
      title: {
        text: "Time",
      },
      labels: {
        datetimeFormatter: {
          hour: "HH:mm",
        },
      },
      min: new Date().setHours(0, 0, 0, 0), // start of the current day
      max: new Date().setHours(23, 59, 59, 999), // end of the current day
      tickAmount: 48, // for 30-minute intervals // 24 hours * 2 (for 30-minute intervals)
      range: 24 * 60 * 60 * 1000, //
    },
    yaxis: {
      title: {
        text: "Humidity (%)",
      },
      min: 0, // You might want to adjust this based on your data
      max: 50,
    },
    tooltip: {
      x: {
        format: "dd MMM yyyy HH:mm",
      },
    },
  };

  const humSeries = [
    {
      name: "Humidity",
      data: humidityData, // Assuming this comes from your state or props
    },
  ];

  const presOptions = {
    chart: {
      id: "pressure-chart",
      type: "line",
      height: 350,
      toolbar: {
        show: true,
      },
    },
    markers: {
      size: 4,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
    },
    colors: linechartBasicColors,
    // title: {
    //   text: "Differential Pressure over Time",

    //   align: "left",
    // },
    xaxis: {
      type: "datetime",
      title: {
        text: "Time",
      },
      labels: {
        datetimeFormatter: {
          hour: "HH:mm",
        },
      },
      min: new Date().setHours(0, 0, 0, 0), // start of the current day
      max: new Date().setHours(23, 59, 59, 999), // end of the current day
      tickAmount: 48, // for 30-minute intervals // 24 hours * 2 (for 30-minute intervals)
      range: 24 * 60 * 60 * 1000, // 24 hours in milliseconds
    },
    yaxis: {
      title: {
        text: "Pressure (Pa)",
      },
      min: 0, // You might want to adjust this based on your data
      max: 20,
    },
    tooltip: {
      x: {
        format: "dd MMM yyyy HH:mm",
      },
    },
  };

  const presSeries = [
    {
      name: "Differential Pressure",
      data: pressureData, // Assuming this comes from your state or props
    },
  ];

  return (
    <div>
      <Row>
        <Col xl={12}>
          {/* Temperature Chart */}
          <Card>
            <CardHeader>Temperature Chart</CardHeader>
            <CardBody>
              <ReactApexChart
                options={tempOptions}
                series={tempSeries}
                type="line"
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col xl={12}>
          <Card>
            <CardHeader>Humidity Chart</CardHeader>
            <CardBody>
              {/* Humidity Chart */}
              <ReactApexChart
                options={humOptions}
                series={humSeries}
                type="line"
              />
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col xl={12}>
          <Card>
            <CardHeader>Differential Pressure Chart</CardHeader>
            <CardBody>
              {/* Pressure Chart */}
              <ReactApexChart
                options={presOptions}
                series={presSeries}
                type="line"
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default EmsSensorChartsNew;

// To implement last 24 hours of data

// import React, { useState, useEffect } from 'react';
// import ReactApexChart from 'react-apexcharts';

// const MyComponent = ({ jsonData }) => {
//   const [temperatureData, setTemperatureData] = useState([]);
//   const [humidityData, setHumidityData] = useState([]);
//   const [pressureData, setPressureData] = useState([]);

//   useEffect(() => {
//     const interval = 30 * 60 * 1000; // 30 minutes in milliseconds
//     const now = new Date();
//     const twentyFourHoursAgo = new Date(now.getTime() - (24 * 60 * 60 * 1000)); // 24 hours ago

//     const filteredData = jsonData.logs.filter(log => {
//       const logDate = new Date(log.timestamp);
//       return logDate >= twentyFourHoursAgo && logDate <= now;
//     });

//     // Assuming data is sorted, if not, sort it by timestamp
//     filteredData.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));

//     const tempData = [];
//     const humData = [];
//     const presData = [];
//     let lastTimestamp = 0;

//     filteredData.forEach(log => {
//       const timestamp = new Date(log.timestamp).getTime();

//       // Process data only if 30 minutes have passed since the last timestamp
//       if (!lastTimestamp || timestamp - lastTimestamp >= interval) {
//         lastTimestamp = timestamp;
//         tempData.push({ x: new Date(log.timestamp), y: log.temperature.value });
//         humData.push({ x: new Date(log.timestamp), y: log.humidity.value });
//         presData.push({ x: new Date(log.timestamp), y: log.differential_pressure.value });
//       }
//     });

//     setTemperatureData(tempData);
//     setHumidityData(humData);
//     setPressureData(presData);
//   }, [jsonData]);

//   // Define chart options and series for each type of data
//   // ...

//   return (
//     <div>
//       {/* Temperature Chart */}
//       <ReactApexChart options={tempOptions} series={[{ name: 'Temperature', data: temperatureData }]} type="line" />
//       {/* Humidity Chart */}
//       <ReactApexChart options={humOptions} series={[{ name: 'Humidity', data: humidityData }]} type="line" />
//       {/* Pressure Chart */}
//       <ReactApexChart options={presOptions} series={[{ name: 'Differential Pressure', data: pressureData }]} type="line" />
//     </div>
//   );
// };

// export default MyComponent;
