import React, { useState, useEffect } from "react";
import DownloadComponent from "./DownloadComponent";

export default function SensorReports() {
  document.title = "Reports | Skyware - EMS Dashboard";
  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0">Sensor Reports</h4>
              </div>
            </div>
          </div>
        </div>
        <DownloadComponent />
      </div>
    </>
  );
}
