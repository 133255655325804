import React, { useEffect, useState } from "react";
import Topbar from "./Topbar";
import SensorTable from "./SensorTable";
// import { APIClient } from "../../network/network_helper";
import axios from "axios";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import CommentModal from "./CommentModal";
import AlertTable from "./AlertTable";

export default function EMSDashboard() {
  // const api = new APIClient();
  const [alertLogs, setAlertLogs] = useState([]);
  const [sensorLogs, setSensorLogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  
  
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0); // You'll need to adjust this based on API response
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalAlerts, setTotalAlerts] = useState(0);

  useEffect(() => {
    fetchData();
    const interval = setInterval(fetchPeriodicData, 10000); // Fetch data every 30000 milliseconds (30 seconds)
    return () => clearInterval(interval);
  }, []);
  
    const fetchPeriodicData = () => {
    fetchData();
    fetchAlertData();
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      setError(false);
      const response = await axios.get(
        "https://admin.skyware-ems.live/api/sensors/get-current-logs"
      );
      console.log("Dashboard:::::::::::::" + response.logs);
      setLoading(false);
      setSensorLogs(response.logs);
    } catch (error) {
      setError(true);
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };
  
   function parseApiResponse(response) {
    try {
      // Check for error in the response
      if (response.error) {
        throw new Error("API responded with an error: " + response.message);
      }

      // Check if alerts is an array
      if (!Array.isArray(response.alerts)) {
        throw new Error(
          "Invalid response format: Expected 'alerts' to be an array"
        );
      }

      // Map each object in the alerts array to a parsed version
      return response.alerts.map((alert) => {
        let parsedObject = {
          _id: alert._id,
          deviceId: alert.device_id || "Unknown",
          alertType: alert.alert_type || "Unknown",
          timestamp: alert.timestamp || "Unknown",
          sensorType: alert.sensor_type || "Unknown",
          value: alert.value || "Unknown",
          unit: alert.unit || "Unknown",
          status: alert.status || "Unknown",
          alertLevel: alert.alert_level || "Unknown",
          isActive: alert.isActive || false,
          comment: alert.comment || "",
          createdAt: alert.createdAt || "Unknown",
          updatedAt: alert.updatedAt || "Unknown",
          __v: alert.__v || 0,
        };

        return parsedObject;
      });
    } catch (error) {
      console.error("Error parsing API response:", error);
      return [];
    }
  }

  const fetchAlertData = async () => {
    try {
      setLoading(true);
      setError(false);
      const alerts = await axios.get(
        `https://admin.skyware-ems.live/api/sensors/get-alert-logs`
      );
      const parsedArray = parseApiResponse(alerts);
      console.log("Alerts:::::::::::::" + parsedArray);
      setLoading(false);
      setAlertLogs(parsedArray);
      setTotalPages(alerts.totalPages || 0);
      setItemsPerPage(alerts.logsPerPage);
      setTotalAlerts(alerts.totalAlerts || 0);
    } catch (error) {
      setError(true);
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };
  
  document.title = "Dashboard | Skyware - EMS Dashboard";
  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0">EMS Dashboard</h4>
              </div>
            </div>
          </div>
        </div>
        {/* {error && <div className="page-content">Something went wrong ...</div>} */}
        {/* {loading && (
          <div className="live-preview">
            <span className="placeholder col-6"></span>
            <span className="placeholder w-75"></span>
            <span className="placeholder" style={{ width: "25%" }}></span>
          </div>
        )} */}
        {/* {!loading && !error && ( */}
        <>
          <Topbar sensorLogs={sensorLogs} alertLogs={alertLogs.length} />
          <SensorTable sensorLogs={sensorLogs} />
          <AlertTable />
        </>
        {/* )} */}
      </div>
    </>
  );
}
