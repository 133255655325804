import React from "react";
import { Card, CardBody, Col } from "reactstrap";
import CommentModal from "../EMSDashboard/CommentModal";

const HighTemperatureAlert = ({
  deviceLocation,
  deviceId,
  dateTime,
  alertLevel,
  sensorType,
}) => {
  console.log("Alert Level: " + alertLevel);

  return (
    <Card
      style={{
        backgroundColor: alertLevel === 1 ? "#FCF0C0" : "#fff5f5",
        borderColor: alertLevel === 1 ? "#F1E0A7" : "#dc3545",
        borderStyle: "solid",
        borderWidth: "1px",
        borderRadius: "4px",
        width: "100%", // Take full width of the parent
      }}
    >
      <CardBody className="d-flex justify-content-between align-items-center p-3">
        <Col xl={10}>
          <div>
            <strong style={{ color: "#0B2031", fontSize: "18px" }}>
              High {sensorType} Alert
            </strong>
            <br></br>
            <strong>{sensorType} may rise above the threshold</strong>
            <br></br>
            <span style={{ color: "#54636F" }}>
              Device Location: {deviceLocation}
            </span>
            <br />
            <span style={{ color: "#54636F" }}>Device Id: {deviceId}</span>
            <br />
            <span style={{ color: "#54636F" }}>Time: {dateTime}</span>
          </div>
        </Col>
        <Col xl={2}>
          <div
            style={{
              background: "#ffffff",
              border:
                alertLevel === 1 ? "2px solid #F1E0A7" : "2px solid #DFA4A9", // Slightly darker border color
              borderRadius: "4px", // Rounded corners
              width: "40px", // Width of the box, adjust as needed
              height: "40px", // Height of the box, adjust as needed
              display: "flex",
              justifyContent: "center", // Center the icon horizontally
              alignItems: "center", // Center the icon vertically
            }}
          >
            <i
              className={
                sensorType === "temperature"
                  ? "ri-celsius-fill label-icon"
                  : "ri-water-percent-line label-icon"
              }
              style={{
                color: alertLevel === 1 ? "#F1E0A7" : "#dc3545",
                fontSize: "24px",
              }}
            ></i>
          </div>
          <div style={{ height: "16px" }}></div>
          {/* <div
            style={{
              background: "#ffffff",
              border:
                alertLevel === 1 ? "2px solid #F1E0A7" : "2px solid #DFA4A9", // Slightly darker border color
              borderRadius: "4px", // Rounded corners
              width: "40px", // Width of the box, adjust as needed
              height: "40px", // Height of the box, adjust as needed
              display: "flex",
              justifyContent: "center", // Center the icon horizontally
              alignItems: "center", // Center the icon vertically
            }}
          >
            <i
              className="ri-check-double-line label-icon"
              style={{
                color: alertLevel === 1 ? "#F1E0A7" : "#dc3545",
                fontSize: "24px",
              }}
            ></i>
          </div> */}
          <CommentModal alertLevel={alertLevel} />
        </Col>
      </CardBody>
    </Card>
  );
};

export default HighTemperatureAlert;
