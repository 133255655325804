import React, { useEffect, useState } from "react";
import Topbar from "./Topbar";
import SensorHistoryTable from "./SensorHistoryTable";
// import { APIClient } from "../../network/network_helper";
import axios from "axios";
import { useParams } from "react-router-dom";
import SensorCharts from "../EMSSensors/SensorCharts";

import EmsSensorChartsNew from "./EmsSensorChartsNew";

export default function EMSAnalytics(props) {
  // const { id } = props;

  let { id } = useParams();

  const [sensorHistoryLogs, setSensorHistoryLogs] = useState([]);
  const [totalLogs, setTotalLogs] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const [deviceDetails, setDeviceDetails] = useState({});

  const [threshold, setThreshold] = useState({});

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const itemLimit = 1000;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = sensorHistoryLogs.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const handlePageClick = (newPage) => {
    setCurrentPage(newPage);
  };

  const pageNumbers = [];
  for (
    let i = 1;
    i <= Math.ceil(sensorHistoryLogs.length / itemsPerPage);
    i++
  ) {
    pageNumbers.push(i);
  }

  useEffect(() => {
    fetchData();
    const interval = setInterval(fetchPeriodicData, 10000); // Fetch sensorHistoryLogs every 30000 milliseconds (30 seconds)
    return () => clearInterval(interval);
  }, []);

  const fetchPeriodicData = () => {
    fetchData();
    fetchDeviceDetails(id);
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      setError(false);
      const response = await axios.get(
        `https://admin.skyware-ems.live/api/sensors/get-sensor-logs-by-device/${id}?page=${currentPage}&limit=${itemLimit}`
      );
      console.log("Sensor Logs:::::::::::::::::" + response.error);
      setLoading(false);
      setSensorHistoryLogs(response.logs);
    } catch (error) {
      setError(true);
      setLoading(false);
      console.error("Error fetching sensorHistoryLogs:", error);
    }
  };

  const fetchDeviceDetails = async (deviceId) => {
    try {
      const response = await axios.get(
        `https://admin.skyware-ems.live/api/device/${deviceId}`
      );
      // Log the entire response data
      console.log("Full response data:", response);

      // Check if response.data is not undefined
      if (!response) {
        console.error("No data found in the response");
        return;
      }
      // Parse the response data
      const data = response;

      // Check if 'device' and 'threshold' properties exist
      if (data.device && data.threshold) {
        // Accessing the device details
        const device = data.device;
        setDeviceDetails(device);
        console.log("Device Details:", device);

        // Accessing the threshold details
        const threshold = data.threshold;
        setThreshold(threshold);
        console.log("Threshold Details:", threshold);
      } else {
        console.error(
          "Response does not contain expected device and threshold properties."
        );
      }
    } catch (error) {
      console.error("Error fetching device details:", error.message);
    }
  };

  document.title = "Analytics | Skyware - EMS Dashboard";
  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0">Device Analytics</h4>
              </div>
            </div>
          </div>
        </div>
        {/* /** Title ends here */}
        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <h5 className="mb-sm-0">Information/Details</h5>
            </div>
            <p className="pl-3 pr-3 text-success">
              <strong>DeviceID :</strong> {id}
            </p>
            <p className="pl-3 pr-3 text-success">
              <strong>Device Location :</strong>{" "}
              {deviceDetails.building +
                " - " +
                deviceDetails.floor +
                " - " +
                deviceDetails.room}
            </p>
            <div className=" d-sm-flex align-items-center justify-content-between mt-3 mb-3">
              <h5 className="mb-sm-0 mt-3 mb-3">Thresholds</h5>
            </div>
            <p className="pl-3 pr-3 text-success">
              <strong>Temperature :</strong>{" "}
              {threshold.temperature_alert1_value +
                " " +
                threshold.temperature_alert1_unit}{" "}
              - Normal &{" "}
              {threshold.temperature_alert2_value +
                " " +
                threshold.temperature_alert2_unit}{" "}
              - Critical
            </p>
            <p className="pl-3 pr-3 text-success ">
              <strong>R. Humidity :</strong>{" "}
              {threshold.humidity_alert1_value +
                " " +
                threshold.humidity_alert1_unit}{" "}
              - Normal &{" "}
              {threshold.humidity_alert2_value +
                " " +
                threshold.humidity_alert2_unit}{" "}
              - Critical
            </p>
            <p className="pl-3 pr-3 text-success">
              <strong>D. Pressure :</strong>
              {"  "}
              {threshold.differential_pressure_alert1_value +
                " " +
                threshold.differential_pressure_alert1_unit}{" "}
              - Normal &{" "}
              {threshold.differential_pressure_alert2_value +
                " " +
                threshold.differential_pressure_alert2_unit}{" "}
              - Critical
            </p>
          </div>
        </div>
        <br />
        <SensorHistoryTable id={id} />
        <br />
        <EmsSensorChartsNew
          id={id}
          sensorHistoryLogs={sensorHistoryLogs}
          dataColors='["--vz-primary"]'
        />
        {/* <SensorCharts id={id} sensorHistoryLogs={sensorHistoryLogs} /> */}
      </div>
    </>
  );
}
