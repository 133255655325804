import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import axios from "axios";
import { Link } from "react-router-dom";
import CommentPopup from "./CommentPopup";
import CommentModal from "./CommentModal";

function AlertTable() {
  const [alertLogs, setAlertLogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0); // You'll need to adjust this based on API response
  const [itemsPerPage, setItemsPerPage] = useState(15);

  const [showPopup, setShowPopup] = useState(false);
  const pageInputRef = React.useRef(null);

  const handleIconClick = () => {
    console.log("Icon clicked!");
    setShowPopup(true);
  };

  const handleSubmitComment = (comment) => {
    console.log("Comment submitted:", comment);
    // Here, you can make an API call to submit the comment
  };

  // Function to format the timestamp into date and time
  const formattedDateAndTime = (timestamp) => {
    const date = new Date(timestamp);

    // Options to ensure 12-hour time format with AM/PM
    const timeOptions = {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true, // Ensure 12-hour format with AM or PM
      timeZone: "Asia/Kolkata", // Specify IST time zone
    };

    const dateOptions = {
      timeZone: "Asia/Kolkata", // Specify IST time zone
    };

    return {
      date: date.toLocaleDateString("en-IN", dateOptions), // Specify locale and time zone
      time: date.toLocaleTimeString("en-IN", timeOptions), // Use the specified locale and options
    };
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  useEffect(() => {
    fetchData(currentPage);
    if (pageInputRef.current) {
      pageInputRef.current.value = currentPage.toString();
    }
    const interval = setInterval(fetchData, 15000); // Fetch data every 30000 milliseconds (30 seconds)
    return () => clearInterval(interval);
  }, [currentPage, itemsPerPage]);

  const fetchData = async (currentPage) => {
    try {
      setLoading(true);
      setError(false);
        const alerts = await axios.get(
        `https://admin.skyware-ems.live/api/sensors/get-alert-logs?page=${currentPage}&limit=${itemsPerPage}&is_active=true`
      );
      const parsedArray = parseApiResponse(alerts);
      console.log("Alerts:::::::::::::" + parsedArray);
      setLoading(false);
      setAlertLogs(parsedArray);
      setTotalPages(alerts.totalPages || 0);
    } catch (error) {
      setError(true);
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  // function parseApiResponse(responseArray) {
  //   try {
  //     // Check if responseArray is an array
  //     if (!Array.isArray(responseArray)) {
  //       throw new Error("Invalid response format: Expected an array");
  //     }

  //     // Map each object in the array to a parsed version
  //     return responseArray.map((response) => {
  //       let parsedObject = {
  //         deviceId: response.device_id || "Unknown",
  //         timestamp: response.timestamp || "Unknown",
  //         measurement: null,
  //         isActive: response.isActive || false,
  //         comment: response.comment || "",
  //         createdAt: response.createdAt || "Unknown",
  //         updatedAt: response.updatedAt || "Unknown",
  //       };

  //       // Check and parse the key measurement field
  //       if (response.differential_pressure) {
  //         parsedObject.measurement = {
  //           type: "Differential Pressure",
  //           value: response.differential_pressure.value,
  //           unit: response.differential_pressure.unit,
  //           status: response.differential_pressure.status,
  //           alertLevel: response.differential_pressure.alert_level,
  //         };
  //       } else if (response.temperature) {
  //         parsedObject.measurement = {
  //           type: "Temperature",
  //           value: response.temperature.value,
  //           unit: response.temperature.unit,
  //           status: response.temperature.status,
  //           alertLevel: response.temperature.alert_level,
  //         };
  //       } else if (response.humidity) {
  //         parsedObject.measurement = {
  //           type: "Humidity",
  //           value: response.humidity.value,
  //           unit: response.humidity.unit,
  //           status: response.humidity.status,
  //           alertLevel: response.humidity.alert_level,
  //         };
  //       }

  //       return parsedObject;
  //     });
  //   } catch (error) {
  //     console.error("Error parsing API response:", error);
  //     return [];
  //   }
  // }

  function parseApiResponse(response) {
    try {
      // Check for error in the response
      if (response.error) {
        throw new Error("API responded with an error: " + response.message);
      }

      // Check if alerts is an array
      if (!Array.isArray(response.alerts)) {
        throw new Error(
          "Invalid response format: Expected 'alerts' to be an array"
        );
      }

      // Map each object in the alerts array to a parsed version
      return response.alerts.map((alert) => {
        let parsedObject = {
          _id: alert._id,
          deviceId: alert.device_id || "Unknown",
          alertType: alert.alert_type || "Unknown",
          timestamp: alert.timestamp || "Unknown",
          sensorType: alert.sensor_type || "Unknown",
          value: alert.value || "Unknown",
          unit: alert.unit || "Unknown",
          status: alert.status || "Unknown",
          alertLevel: alert.alert_level || "Unknown",
          isActive: alert.isActive || false,
          comment: alert.comment || "",
          createdAt: alert.createdAt || "Unknown",
          updatedAt: alert.updatedAt || "Unknown",
          __v: alert.__v || 0,
        };

        return parsedObject;
      });
    } catch (error) {
      console.error("Error parsing API response:", error);
      return [];
    }
  }

  // const renderPagination = () => {
  //   let pages = [];
  //   for (let i = 1; i <= totalPages; i++) {
  //     pages.push(
  //       <PaginationItem active={i === currentPage} key={i}>
  //         <PaginationLink onClick={() => handlePageChange(i)}>
  //           {i}
  //         </PaginationLink>
  //       </PaginationItem>
  //     );
  //   }
  //   return <Pagination aria-label="Alert logs navigation">{pages}</Pagination>;
  // };

  // function renderPagination() {
  //   let items = [];
  //   const pageNeighbours = 2; // How many pages to show around the current page
  //   const totalNumbers = pageNeighbours * 2 + 3; // Total page numbers to show at any time
  //   const totalBlocks = totalNumbers + 2; // Total including Next and Previous buttons

  //   if (totalPages > totalBlocks) {
  //     let pages = [];

  //     const leftBound = Math.max(2, currentPage - pageNeighbours);
  //     const rightBound = Math.min(totalPages - 1, currentPage + pageNeighbours);
  //     const beforeCurrentPage = [
  //       ...Array(currentPage - leftBound + 1).keys(),
  //     ].map((i) => leftBound + i);
  //     const afterCurrentPage = [
  //       ...Array(rightBound - currentPage + 1).keys(),
  //     ].map((i) => currentPage + i);

  //     pages = [1, ...beforeCurrentPage, ...afterCurrentPage, totalPages];

  //     // Dots logic
  //     const hasLeftSpill = leftBound > 2;
  //     const hasRightSpill = totalPages - rightBound > 1;
  //     const spillOffset = totalNumbers - (pages.length - 1);

  //     if (hasLeftSpill && !hasRightSpill) {
  //       const extraPages = [...Array(spillOffset).keys()].map(
  //         (i) => leftBound - spillOffset + i
  //       );
  //       pages = [1, ...extraPages, ...pages.slice(1)];
  //     } else if (!hasLeftSpill && hasRightSpill) {
  //       const extraPages = [...Array(spillOffset).keys()].map(
  //         (i) => rightBound + i + 1
  //       );
  //       pages = [...pages.slice(0, -1), ...extraPages, totalPages];
  //     } else if (hasLeftSpill && hasRightSpill) {
  //       pages = [1, ...pages.slice(1, -1), totalPages];
  //     }

  //     items = pages.map((page) => {
  //       return (
  //         <PaginationItem active={page === currentPage} key={page}>
  //           <PaginationLink onClick={() => handlePageChange(page)}>
  //             {page}
  //           </PaginationLink>
  //         </PaginationItem>
  //       );
  //     });

  //     // Previous Page
  //     if (currentPage > 1) {
  //       items.unshift(
  //         <PaginationItem key="prev">
  //           <PaginationLink
  //             previous
  //             onClick={() => handlePageChange(currentPage - 1)}
  //           />
  //         </PaginationItem>
  //       );
  //     }

  //     // Next Page
  //     if (currentPage < totalPages) {
  //       items.push(
  //         <PaginationItem key="next">
  //           <PaginationLink
  //             next
  //             onClick={() => handlePageChange(currentPage + 1)}
  //           />
  //         </PaginationItem>
  //       );
  //     }
  //   } else {
  //     items = [...Array(totalPages).keys()].map((i) => (
  //       <PaginationItem active={i + 1 === currentPage} key={i + 1}>
  //         <PaginationLink onClick={() => handlePageChange(i + 1)}>
  //           {i + 1}
  //         </PaginationLink>
  //       </PaginationItem>
  //     ));
  //   }

  //   return (
  //     <Pagination aria-label="Page navigation example">{items}</Pagination>
  //   );
  // }

  const handleItemsPerPageChange = (newItemsPerPage) => {
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1); // Reset to the first page
    fetchData(1, newItemsPerPage); // Refetch data with new items per page
  };

  function renderPagination() {
    // Input ref to manage the page input

    const goToPage = (page) => {
      const newPage = Math.max(0, Math.min(page, totalPages));
      if (newPage !== currentPage) {
        handlePageChange(newPage);
      }
    };

    // Handler for the page input
    const handlePageInput = (e) => {
      if (e.key === "Enter") {
        const page = parseInt(pageInputRef.current.value, 10);
        if (!isNaN(page)) {
          goToPage(page);
        }
      }
    };

    return (
      <div className="pagination-wrapper">
        <button
          onClick={() => goToPage(currentPage - 1)}
          disabled={currentPage === 1}
        >
          {"<"}
        </button>
        <input
          ref={pageInputRef}
          type="number"
          defaultValue={currentPage}
          onKeyPress={handlePageInput}
          min="1"
          max={totalPages}
          style={{ width: "5em", textAlign: "center" }}
        />
        <button
          onClick={() => goToPage(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          {">"}
        </button>
        <span style={{ marginLeft: "0.5em" }}>
          Page {currentPage} of {totalPages}
        </span>
        <span style={{ marginLeft: "3em" }}>Items per page:</span>
        <select
          defaultValue={itemsPerPage}
          onChange={(e) => {
            // Assuming you have a function to handle the number of items per page
            handleItemsPerPageChange(parseInt(e.target.value, 10));
          }}
        >
          {[10, 15, 20, 25, 50].map((size) => (
            <option key={size} value={size}>
              {size}
            </option>
          ))}
        </select>
      </div>
    );
  }

  return (
    <>
      <CommentPopup
        isOpen={showPopup}
        onSubmit={handleSubmitComment}
        onClose={handleClosePopup}
      />
      <Row>
        <Col xl={12}>
          <Card>
            <CardHeader className="align-items-center d-flex bg-success">
              <h4 className="card-title mb-0 flex-grow-1 text-light">
                Sensor Alerts
              </h4>
              {/* <div className="flex-shrink-0">
                <UncontrolledDropdown className="card-header-dropdown">
                  <DropdownToggle
                    tag="a"
                    className="text-reset dropdown-btn"
                    role="button"
                  >
                    <span className="text-light">
                      02 Nov 2021 to 31 Dec 2021
                      <i className="mdi mdi-chevron-down ms-1"></i>
                    </span>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-end">
                    <DropdownItem>Today</DropdownItem>
                    <DropdownItem>Last Week</DropdownItem>
                    <DropdownItem>Last Month</DropdownItem>
                    <DropdownItem>Current Year</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div> */}
            </CardHeader>

            <CardBody>
              <div className="table-responsive table-card">
                <table className="table table-borderless table-hover table-nowrap align-middle mb-0">
                  <thead className="table-light">
                    <tr className="text-muted">
                      <th scope="col">Date</th>
                      <th scope="col">Time</th>
                      <th scope="col">DeviceID</th>
                      <th scope="col">Device Location</th>

                      <th scope="col">Sensor</th>
                      <th scope="col">Value</th>
                      <th scope="col">Alert Level</th>
                      {/* <th scope="col" style={{ width: "16%" }}>
                        Status
                      </th> */}
                      <th scope="col" style={{ width: "16%" }}>
                        Action
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {(alertLogs || []).map((item, index) => (
                      <tr key={index}>
                        <td scope="col">
                          {formattedDateAndTime(item.createdAt).date}
                        </td>
                        <td scope="col">
                          {formattedDateAndTime(item.createdAt).time}
                        </td>
                        <td scope="col">{item.deviceId}</td>
                        <td scope="col">Floor1/Room1</td>
                        <td scope="col">
                          <div className="text-nowrap"> {item.sensorType}</div>
                        </td>
                        <td>
                          <div className="text-nowrap">
                            {" "}
                            {item.value} {item.unit}
                          </div>
                        </td>
                        <td>
                          <span
                            className={
                              "badge bg-" +
                              (item.alertLevel === 1 ? "warning" : "danger") +
                              "-subtle p-2 text-" +
                              (item.alertLevel === 1 ? "warning" : "danger")
                            }
                          >
                            {item.alertLevel === 1 ? "Alert" : "Alarm"}
                          </span>
                        </td>
                        {/* <td>
                          <span
                            className={
                              "badge bg-" +
                              (item.isActive === false ? "success" : "danger") +
                              "-subtle p-2 text-" +
                              (item.isActive === false ? "success" : "danger")
                            }
                          >
                            {item.isActive === true ? "Active" : "Inactive"}
                          </span>
                        </td> */}
                        <td>
                          <CommentModal
                            alertId={item._id}
                            alertLevel={item.alertLevel}
                          />
                          {/* <i
                            className="ri-check-double-line label-icon"
                            style={{
                              cursor: "pointer",
                              color: "#dc3545",
                              fontSize: "24px",
                            }}
                            onClick={handleIconClick}
                          ></i> */}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div
                className="d-flex align-items-center w-100 justify-content-end mt-3"
                style={{ paddingTop: 15 }}
              >
                {" "}
                {renderPagination()}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default AlertTable;
