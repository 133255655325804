import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  UncontrolledAlert,
} from "reactstrap";
import { Link } from "react-router-dom";
import { dealsStatus } from "../../common/data";
import axios from "axios";
import HighTemperatureAlert from "../EMSAlerts/HighTemperatureAlert";

const SensorTable = (props) => {
  const sensorLogs = props.sensorLogs;

  const formattedDateAndTime = (timestamp) => {
    const date = new Date(timestamp);

    // Options to ensure 12-hour time format with AM/PM
    const timeOptions = {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true, // Ensure 12-hour format with AM or PM
      timeZone: "Asia/Kolkata", // Specify IST time zone
    };

    const dateOptions = {
      timeZone: "Asia/Kolkata", // Specify IST time zone
    };

    return {
      date: date.toLocaleDateString("en-IN", dateOptions), // Specify locale and time zone
      time: date.toLocaleTimeString("en-IN", timeOptions), // Use the specified locale and options
    };
  };

  // function getDeviceStatus(lastDate, lastTime) {
  //   // Combine the date and time into a single string
  //   const lastLogTimeString = `${lastDate}T${lastTime}`;

  //   // Create a Date object from the combined date and time string
  //   const lastLogTime = new Date(lastLogTimeString).getTime();
  //   const currentTime = Date.now(); // Current time in milliseconds

  //   // Calculate time difference in milliseconds
  //   const timeDiff = currentTime - lastLogTime;

  //   // Define 12 hours in milliseconds
  //   const twelveHours = 12 * 60 * 60 * 1000;

  //   // Check if the time difference exceeds 12 hours
  //   if (timeDiff > twelveHours) {
  //     return false; // If more than 12 hours have passed
  //   } else {
  //     return true; // Otherwise, the device is still active
  //   }
  // }

  function getDeviceStatus(lastUpdateAtTime) {
    // Combine the date and time into a single string
    // const lastLogTimeString = `${lastDate}T${lastTime}`;

    // Create a Date object from the combined date and time string
    const lastLogTime = new Date(lastUpdateAtTime).getTime();
    const currentTime = Date.now(); // Current time in milliseconds

    // Calculate time difference in milliseconds
    const timeDiff = currentTime - lastLogTime;

    // Define 12 hours in milliseconds
    const twelveHours = 12 * 60 * 60 * 1000;

    // Check if the time difference exceeds 12 hours
    if (timeDiff > twelveHours) {
      return false; // If more than 12 hours have passed
    } else {
      return true; // Otherwise, the device is still active
    }
  }

  return (
    <React.Fragment>
      <Row>
        <Col xl={12}>
          <Card>
            <CardHeader className="align-items-center d-flex bg-success">
              <h4 className="card-title mb-0 flex-grow-1 text-light">
                Real-time Sensor Data
              </h4>
            </CardHeader>

            <CardBody>
              <div className="table-responsive table-card">
                <table className="table table-borderless table-hover table-nowrap align-middle mb-0">
                  <thead className="table-light">
                    <tr className="text-muted">
                      <th scope="col">Device ID</th>
                      <th scope="col">Device Name</th>
                      <th scope="col">Device Location</th>

                      <th scope="col">Temperature</th>
                      <th scope="col">Humidity</th>
                      <th scope="col">Diff. Pressure</th>
                      <th scope="col" style={{ width: "16%" }}>
                        Status
                      </th>
                      <th scope="col">Last Updated Date</th>
                      <th scope="col">Time</th>
                    </tr>
                  </thead>

                  <tbody>
                    {(sensorLogs || []).map((item, index) => (
                      <tr key={index}>
                        <td scope="col">
                          <Link to={"/devices/" + item.device_id}>
                            {item.device_id}
                          </Link>
                        </td>
                        <td scope="col">Device {index}</td>
                        <td scope="col">
                          {item.device &&
                            `${item.device.floor} / ${item.device.room}`}
                        </td>
                        <td scope="col">
                          <div className="text-nowrap">
                            {item.temperature.value} {item.temperature.unit}
                          </div>
                        </td>
                        <td>
                          <div className="text-nowrap">
                            {item.humidity.value} {item.humidity.unit}
                          </div>
                        </td>
                        <td>
                          <div className="text-nowrap">
                            {item.differential_pressure.value}{" "}
                            {item.differential_pressure.unit}
                          </div>
                        </td>
                        <td>
                          <span
                            className={
                              "badge bg-" +
                              (getDeviceStatus(item.updatedAt)
                                ? "success"
                                : "danger") +
                              "-subtle p-2 text-" +
                              (getDeviceStatus(item.updatedAt)
                                ? "success"
                                : "danger")
                            }
                          >
                            {getDeviceStatus(item.updatedAt)
                              ? "Active"
                              : "Inactive"}
                          </span>
                        </td>
                        <td scope="col">
                          {formattedDateAndTime(item.updatedAt).date}
                        </td>
                        <td scope="col">
                          {formattedDateAndTime(item.updatedAt).time}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </CardBody>
          </Card>
        </Col>
        {/* <Col xl={3}>
          <Card>
            <CardHeader className="align-items-center d-flex bg-success">
              <h4 className="card-title mb-0 flex-grow-1 text-light">Alerts</h4> */}
        {/* <div className="flex-shrink-0">
                <UncontrolledDropdown className="card-header-dropdown">
                  <DropdownToggle
                    tag="a"
                    className="text-reset dropdown-btn"
                    role="button"
                  >
                    <span className="text-light">
                      4 Hours
                      <i className="mdi mdi-chevron-down ms-1"></i>
                    </span>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-end">
                    <DropdownItem>8 Hours</DropdownItem>
                    <DropdownItem>16 Hours</DropdownItem>
                    <DropdownItem>24 Hours</DropdownItem>
                    <DropdownItem>48 Hours</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div> */}
        {/* </CardHeader> */}

        {/* <CardBody>
              {(alertLogs || []).map((item, index) => (
                <HighTemperatureAlert
                  deviceId={item.deviceId}
                  alertLevel={item.alertLevel}
                  dateTime={item.timestamp}
                  deviceLocation="Floor 1"
                  key={index}
                />
              ))}
            </CardBody>
          </Card>
        </Col> */}
      </Row>
    </React.Fragment>
  );
};

export default SensorTable;
