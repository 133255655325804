import React, { useEffect, useState } from "react";
import {
  Alert,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  UncontrolledAlert,
} from "reactstrap";
import CommentModal from "../EMSDashboard/CommentModal";
import HighTemperatureAlert from "./HighTemperatureAlert";
import axios from "axios";
import AllAlertTable from "./AllAlertTable";

export default function EMSAlerts() {
  const [alertLogs, setAlertLogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  useEffect(() => {
    fetchData();
    const interval = setInterval(fetchData, 15000); // Fetch data every 30000 milliseconds (30 seconds)
    return () => clearInterval(interval);
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      setError(false);
      const alerts = await axios.get(
        "https://admin.skyware-ems.live/api/sensors/get-alert-logs?page=1&limit=100"
      );
      const parsedArray = parseApiResponse(alerts);
      console.log("Alerts:::::::::::::" + parsedArray);
      setLoading(false);
      setAlertLogs(parsedArray);
    } catch (error) {
      setError(true);
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  function parseApiResponse(response) {
    try {
      // Check for error in the response
      if (response.error) {
        throw new Error("API responded with an error: " + response.message);
      }

      // Check if alerts is an array
      if (!Array.isArray(response.alerts)) {
        throw new Error(
          "Invalid response format: Expected 'alerts' to be an array"
        );
      }

      // Map each object in the alerts array to a parsed version
      return response.alerts.map((alert) => {
        let parsedObject = {
          _id: alert._id,
          deviceId: alert.device_id || "Unknown",
          alertType: alert.alert_type || "Unknown",
          timestamp: alert.timestamp || "Unknown",
          sensorType: alert.sensor_type || "Unknown",
          value: alert.value || "Unknown",
          unit: alert.unit || "Unknown",
          status: alert.status || "Unknown",
          alertLevel: alert.alert_level || "Unknown",
          isActive: alert.isActive || false,
          comment: alert.comment || "",
          createdAt: alert.createdAt || "Unknown",
          updatedAt: alert.updatedAt || "Unknown",
          __v: alert.__v || 0,
        };

        return parsedObject;
      });
    } catch (error) {
      console.error("Error parsing API response:", error);
      return [];
    }
  }

  document.title = "Alerts | Skyware - EMS Dashboard";
  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0">Sensor Alerts</h4>
              </div>
            </div>
          </div>
        </div>
        <Row>
          <Col xl={12}>
            <AllAlertTable />
          </Col>
        </Row>
      </div>
    </>
  );
}
