import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  UncontrolledAlert,
} from "reactstrap";
import axios from "axios";
import { Link } from "react-router-dom";
import CommentPopup from "./CommentPopup";
import CommentModal from "./CommentModal";
import { formatDateAndTime } from "../EMSUtils/utils";

function AllAlertTable() {
  const [alertLogs, setAlertLogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0); // You'll need to adjust this based on API response
  const [itemsPerPage, setItemsPerPage] = useState(15);

  const [showPopup, setShowPopup] = useState(false);
  const pageInputRef = React.useRef(null);
  const handleIconClick = () => {
    console.log("Icon clicked!");
    setShowPopup(true);
  };

  const handleSubmitComment = (comment) => {
    console.log("Comment submitted:", comment);
    // Here, you can make an API call to submit the comment
  };

  // Function to format the timestamp into date and time

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  useEffect(() => {
    fetchData();
    const interval = setInterval(fetchData, 15000); // Fetch data every 30000 milliseconds (30 seconds)
    if (pageInputRef.current) {
      pageInputRef.current.value = currentPage.toString();
    }
    return () => clearInterval(interval);
  }, [currentPage, itemsPerPage]);

  const fetchData = async () => {
    try {
      setLoading(true);
      setError(false);
      const alerts = await axios.get(
        `https://admin.skyware-ems.live/api/sensors/get-alert-logs?page=${currentPage}&limit=${itemsPerPage}`
      );
      const parsedArray = parseApiResponse(alerts);
      console.log("Alerts:::::::::::::" + parsedArray);
      setLoading(false);
      setAlertLogs(parsedArray);
      setTotalPages(alerts.totalPages || 0);
    } catch (error) {
      setError(true);
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  function parseApiResponse(response) {
    try {
      // Check for error in the response
      if (response.error) {
        throw new Error("API responded with an error: " + response.message);
      }

      // Check if alerts is an array
      if (!Array.isArray(response.alerts)) {
        throw new Error(
          "Invalid response format: Expected 'alerts' to be an array"
        );
      }

      // Map each object in the alerts array to a parsed version
      return response.alerts.map((alert) => {
        let parsedObject = {
          _id: alert._id,
          deviceId: alert.device_id || "Unknown",
          alertType: alert.alert_type || "Unknown",
          timestamp: alert.timestamp || "Unknown",
          sensorType: alert.sensor_type || "Unknown",
          value: alert.value || "Unknown",
          unit: alert.unit || "Unknown",
          status: alert.status || "Unknown",
          alertLevel: alert.alert_level || "Unknown",
          isActive: alert.isActive || false,
          comment: alert.comment || "",
          createdAt: alert.createdAt || "Unknown",
          updatedAt: alert.updatedAt || "Unknown",
          __v: alert.__v || 0,
        };

        return parsedObject;
      });
    } catch (error) {
      console.error("Error parsing API response:", error);
      return [];
    }
  }

  const handleItemsPerPageChange = (newItemsPerPage) => {
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1); // Reset to the first page
    fetchData(1, newItemsPerPage); // Refetch data with new items per page
  };

  function renderPagination() {
    // Input ref to manage the page input

    const goToPage = (page) => {
      const newPage = Math.max(0, Math.min(page, totalPages));
      if (newPage !== currentPage) {
        handlePageChange(newPage);
      }
    };

    // Handler for the page input
    const handlePageInput = (e) => {
      if (e.key === "Enter") {
        const page = parseInt(pageInputRef.current.value, 10);
        if (!isNaN(page)) {
          goToPage(page);
        }
      }
    };

    return (
      <div className="pagination-wrapper">
        <button
          onClick={() => goToPage(currentPage - 1)}
          disabled={currentPage === 1}
        >
          {"<"}
        </button>
        <input
          ref={pageInputRef}
          type="number"
          defaultValue={currentPage}
          onKeyPress={handlePageInput}
          min="1"
          max={totalPages}
          style={{ width: "5em", textAlign: "center" }}
        />
        <button
          onClick={() => goToPage(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          {">"}
        </button>
        <span style={{ marginLeft: "0.5em" }}>
          Page {currentPage} of {totalPages}
        </span>
        <span style={{ marginLeft: "3em" }}>Items per page:</span>
        <select
          defaultValue={itemsPerPage}
          onChange={(e) => {
            // Assuming you have a function to handle the number of items per page
            handleItemsPerPageChange(parseInt(e.target.value, 10));
          }}
        >
          {[10, 15, 20, 25, 50].map((size) => (
            <option key={size} value={size}>
              {size}
            </option>
          ))}
        </select>
      </div>
    );
  }

  return (
    <>
      <CommentPopup
        isOpen={showPopup}
        onSubmit={handleSubmitComment}
        onClose={handleClosePopup}
      />
      <Row>
        <Col xl={12}>
          <Card>
            <CardHeader className="align-items-center d-flex bg-success">
              <h4 className="card-title mb-0 flex-grow-1 text-light">
                Sensor Alerts
              </h4>
              {/* <div className="flex-shrink-0">
                <UncontrolledDropdown className="card-header-dropdown">
                  <DropdownToggle
                    tag="a"
                    className="text-reset dropdown-btn"
                    role="button"
                  >
                    <span className="text-light">
                      02 Nov 2021 to 31 Dec 2021
                      <i className="mdi mdi-chevron-down ms-1"></i>
                    </span>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-end">
                    <DropdownItem>Today</DropdownItem>
                    <DropdownItem>Last Week</DropdownItem>
                    <DropdownItem>Last Month</DropdownItem>
                    <DropdownItem>Current Year</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div> */}
            </CardHeader>

            <CardBody>
              <div className="table-responsive table-card">
                <table className="table table-borderless table-hover table-nowrap align-middle mb-0">
                  <thead className="table-light">
                    <tr className="text-muted">
                      <th scope="col">Date</th>
                      <th scope="col">Time</th>
                      <th scope="col">DeviceID</th>
                      <th scope="col">Device Location</th>

                      <th scope="col">Sensor</th>
                      <th scope="col">Value</th>
                      <th scope="col">Alert Level</th>
                      {/* <th scope="col" style={{ width: "16%" }}>
                        Status
                      </th> */}
                      <th scope="col" style={{ width: "16%" }}>
                        Action
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {(alertLogs || []).map((item, index) => (
                      <tr key={index}>
                        <td scope="col">
                          {formatDateAndTime(item.createdAt).date}
                        </td>
                        <td scope="col">
                          {formatDateAndTime(item.createdAt).time}
                        </td>
                        <td scope="col">{item.deviceId}</td>
                        <td scope="col">Floor1/Room1</td>
                        <td scope="col">
                          <div className="text-nowrap"> {item.sensorType}</div>
                        </td>
                        <td>
                          <div className="text-nowrap">
                            {" "}
                            {item.value} {item.unit}
                          </div>
                        </td>
                        <td>
                          <span
                            className={
                              "badge bg-" +
                              (item.alertLevel === 1 ? "warning" : "danger") +
                              "-subtle p-2 text-" +
                              (item.alertLevel === 1 ? "warning" : "danger")
                            }
                          >
                            {item.alertLevel === 1 ? "Alert" : "Alarm"}
                          </span>
                        </td>
                        {/* <td>
                          <span
                            className={
                              "badge bg-" +
                              (item.isActive === false ? "success" : "danger") +
                              "-subtle p-2 text-" +
                              (item.isActive === false ? "success" : "danger")
                            }
                          >
                            {item.isActive === true ? "Active" : "Inactive"}
                          </span>
                        </td> */}
                        <td>
                          <CommentModal />
                          {/* <i
                            className="ri-check-double-line label-icon"
                            style={{
                              cursor: "pointer",
                              color: "#dc3545",
                              fontSize: "24px",
                            }}
                            onClick={handleIconClick}
                          ></i> */}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div
                className="d-flex align-items-center w-100 justify-content-end mt-3"
                style={{ paddingTop: 15 }}
              >
                {" "}
                {renderPagination()}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default AllAlertTable;
