 export const formatDateAndTime = (timestamp) => {
    const date = new Date(timestamp);

    // Options to ensure 12-hour time format with AM/PM
    const timeOptions = {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true, // Ensure 12-hour format with AM or PM
      timeZone: "Asia/Kolkata", // Specify IST time zone
    };

    const dateOptions = {
      timeZone: "Asia/Kolkata", // Specify IST time zone
    };

    return {
      date: date.toLocaleDateString("en-IN", dateOptions), // Specify locale and time zone
      time: date.toLocaleTimeString("en-IN", timeOptions), // Use the specified locale and options
    };
  };