// Import Images
import btcCoin from "../../../assets/images/svg/crypto-icons/btc.svg";
import ltcCoin from "../../../assets/images/svg/crypto-icons/ltc.svg";
import ethCoin from "../../../assets/images/svg/crypto-icons/eth.svg";
import etcCoin from "../../../assets/images/svg/crypto-icons/etc.svg";
import bnbCoin from "../../../assets/images/svg/crypto-icons/bnb.svg";
import usdtCoin from "../../../assets/images/svg/crypto-icons/usdt.svg";
import dashCoin from "../../../assets/images/svg/crypto-icons/dash.svg";
import neoCoin from "../../../assets/images/svg/crypto-icons/neo.svg";
import dogeCoin from "../../../assets/images/svg/crypto-icons/doge.svg";
import aaveCoin from "../../../assets/images/svg/crypto-icons/aave.svg";

import img1 from "../../../assets/images/small/img-1.jpg";
import img2 from "../../../assets/images/small/img-2.jpg";
import img3 from "../../../assets/images/small/img-3.jpg";
import img6 from "../../../assets/images/small/img-6.jpg";

const emsWidgets = [
  {
    id: 1,
    icon: "ri-cpu-line",
    label: "Total Devices",
    counter: "200",
    badge: "ri-arrow-up-s-fill",
    badgeColor: "success",
    percentage: "6.24",
    decimal: "",
    prefix: "",
    separator: ",",
  },
  {
    id: 2,
    icon: "ri-arrow-up-circle-fill",
    label: "Total Alerts",
    counter: "20",
    badge: "ri-arrow-up-s-fill",
    badgeColor: "success",
    percentage: "3.67",
    decimal: "0",
    prefix: "",
    separator: ",",
  },
  {
    id: 3,
    icon: "ri-arrow-down-circle-fill",
    label: "Maintenance",
    counter: "15",
    badge: "ri-arrow-down-s-fill",
    badgeColor: "danger",
    percentage: "4.80",
    decimal: "",
    prefix: "",
    separator: ",",
  },
  {
    id: 4,
    icon: "ri-arrow-down-circle-fill",
    label: "Time",
    counter: "5",
    badge: "ri-arrow-down-s-fill",
    badgeColor: "danger",
    percentage: "4.80",
    decimal: "",
    prefix: "",
    separator: ",",
  },
  {
    id: 5,
    icon: "ri-arrow-down-circle-fill",
    label: "Best Location",
    counter: "5",
    badge: "ri-arrow-down-s-fill",
    badgeColor: "danger",
    percentage: "4.80",
    decimal: "",
    prefix: "",
    separator: ",",
  },
  {
    id: 6,
    icon: "ri-arrow-down-circle-fill",
    label: "Worst Location",
    counter: "5",
    badge: "ri-arrow-down-s-fill",
    badgeColor: "danger",
    percentage: "4.80",
    decimal: "",
    prefix: "",
    separator: ",",
  },
];

export { emsWidgets };
