import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  UncontrolledAlert,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import axios from "axios";
import { formatDateAndTime } from "../EMSUtils/utils";

const SensorHistoryTable = (props) => {
  // const sensorHistoryLogs = props.sensorHistoryLogs;
  const { id } = props;

  const [sensorHistoryLogs, setSensorHistoryLogs] = useState([]);
  const [totalLogs, setTotalLogs] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0); // You'll need to adjust this based on API response
  const [itemsPerPage, setItemsPerPage] = useState(15);

  const pageInputRef = React.useRef(null);

  // const indexOfLastItem = currentPage * itemsPerPage;
  // const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  // const currentItems = sensorHistoryLogs.slice(
  //   indexOfFirstItem,
  //   indexOfLastItem
  // );

  const handlePageClick = (newPage) => {
    setCurrentPage(newPage);
  };

  const pageNumbers = [];
  for (
    let i = 1;
    i <= Math.ceil(sensorHistoryLogs.length / itemsPerPage);
    i++
  ) {
    pageNumbers.push(i);
  }

  useEffect(() => {
    fetchData();
    const interval = setInterval(fetchData, 15000); // Fetch sensorHistoryLogs every 30000 milliseconds (30 seconds)
    return () => clearInterval(interval);
  }, [currentPage, itemsPerPage]);

  const fetchData = async () => {
    try {
      setLoading(true);
      setError(false);
      const response = await axios.get(
        `https://admin.skyware-ems.live/api/sensors/get-sensor-logs-by-device/${id}?page=${currentPage}&limit=${itemsPerPage}`
      );
      console.log("Sensor Logs:::::::::::::::::" + response.error);
      setLoading(false);
      setSensorHistoryLogs(response.logs);
      setTotalPages(response.totalPages || 0);
    } catch (error) {
      setError(true);
      setLoading(false);
      console.error("Error fetching sensorHistoryLogs:", error);
    }
  };

  // Function to format the timestamp into date and time
  const formattedDateAndTime = (timestamp) => {
    const date = new Date(timestamp);

    // Options to ensure 12-hour time format with AM/PM
    const timeOptions = {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true, // Ensure 12-hour format with AM or PM
      timeZone: "Asia/Kolkata", // Specify IST time zone
    };

    const dateOptions = {
      timeZone: "Asia/Kolkata", // Specify IST time zone
    };

    return {
      date: date.toLocaleDateString("en-IN", dateOptions), // Specify locale and time zone
      time: date.toLocaleTimeString("en-IN", timeOptions), // Use the specified locale and options
    };
  };

  const handleItemsPerPageChange = (newItemsPerPage) => {
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1); // Reset to the first page
    fetchData(1, newItemsPerPage); // Refetch data with new items per page
  };

  function renderPagination() {
    // Input ref to manage the page input

    const goToPage = (page) => {
      const newPage = Math.max(0, Math.min(page, totalPages));
      if (newPage !== currentPage) {
        handlePageChange(newPage);
      }
    };

    const handlePageChange = (newPage) => {
      setCurrentPage(newPage);
    };

    // Handler for the page input
    const handlePageInput = (e) => {
      if (e.key === "Enter") {
        const page = parseInt(pageInputRef.current.value, 10);
        if (!isNaN(page)) {
          goToPage(page);
        }
      }
    };

    return (
      <div className="pagination-wrapper">
        <button
          onClick={() => goToPage(currentPage - 1)}
          disabled={currentPage === 1}
        >
          {"<"}
        </button>
        <input
          ref={pageInputRef}
          type="number"
          defaultValue={currentPage}
          onKeyPress={handlePageInput}
          min="1"
          max={totalPages}
          style={{ width: "5em", textAlign: "center" }}
        />
        <button
          onClick={() => goToPage(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          {">"}
        </button>
        <span style={{ marginLeft: "0.5em" }}>
          Page {currentPage} of {totalPages}
        </span>
        <span style={{ marginLeft: "3em" }}>Items per page:</span>
        <select
          defaultValue={itemsPerPage}
          onChange={(e) => {
            // Assuming you have a function to handle the number of items per page
            handleItemsPerPageChange(parseInt(e.target.value, 10));
          }}
        >
          {[10, 15, 20, 25, 50].map((size) => (
            <option key={size} value={size}>
              {size}
            </option>
          ))}
        </select>
      </div>
    );
  }

  return (
    <React.Fragment>
      <Row>
        <Col xl={12}>
          <Card>
            <CardHeader className="align-items-center d-flex bg-success">
              <h4 className="card-title mb-0 flex-grow-1 text-light">
                Sensor Data
              </h4>
              {/* <div className="flex-shrink-0">
                <UncontrolledDropdown className="card-header-dropdown">
                  <DropdownToggle
                    tag="a"
                    className="text-reset dropdown-btn"
                    role="button"
                  >
                    <span className="text-light">
                      02 Nov 2021 to 31 Dec 2021
                      <i className="mdi mdi-chevron-down ms-1"></i>
                    </span>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-end">
                    <DropdownItem>Today</DropdownItem>
                    <DropdownItem>Last Week</DropdownItem>
                    <DropdownItem>Last Month</DropdownItem>
                    <DropdownItem>Current Year</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div> */}
            </CardHeader>

            <CardBody>
              <div className="table-responsive table-card">
                <Table className="table table-borderless table-hover table-nowrap align-middle mb-0">
                  <thead className="table-light">
                    <tr className="text-muted">
                      {/* <th scope="col">Device ID</th> */}
                      {/* <th scope="col">Device Name</th> */}

                      {/* <th scope="col">Timestamp</th> */}
                      <th scope="col">Date</th>
                      <th scope="col">Time</th>
                      <th scope="col">Temperature</th>
                      <th scope="col">Humidity</th>
                      <th scope="col">Diff. Pressure</th>
                      {/* <th scope="col" style={{ width: "16%" }}>
                        Status
                      </th> */}
                    </tr>
                  </thead>

                  <tbody>
                    {(sensorHistoryLogs || []).map((item, index) => (
                      <tr key={index}>
                        {/* <td scope="col">
                          <Link to={"/devices/" + item.device_id}>
                            {item.device_id}
                          </Link>
                        </td>
                        <td scope="col">Device {index}</td> */}
                        {/* <td scope="col">{item.timestamp}</td> */}
                        <td scope="col">
                          {formattedDateAndTime(item.createdAt).date}
                        </td>
                        <td scope="col">
                          {formattedDateAndTime(item.createdAt).time}
                        </td>
                        <td scope="col">
                          <div className="text-nowrap">
                            {item.temperature.value} {item.temperature.unit}
                          </div>
                        </td>
                        <td>
                          <div className="text-nowrap">
                            {item.humidity.value} {item.humidity.unit}
                          </div>
                        </td>
                        <td>
                          <div className="text-nowrap">
                            {item.differential_pressure.value}{" "}
                            {item.differential_pressure.unit}
                          </div>
                        </td>
                        {/* <td>
                          <span
                            className={
                              "badge bg-" +
                              (item.temperature.status === 1
                                ? "success"
                                : "danger") +
                              "-subtle p-2 text-" +
                              (item.temperature.status === 1
                                ? "success"
                                : "danger")
                            }
                          >
                            {item.temperature.status === 1
                              ? "Active"
                              : "Inactive"}
                          </span>
                        </td> */}
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <div className="d-flex justify-content-end m-2">
                  {/* <Pagination>
                    {pageNumbers.map((number) => (
                      <PaginationItem
                        key={number}
                        active={number === currentPage}
                      >
                        <PaginationLink onClick={() => handlePageClick(number)}>
                          {number}
                        </PaginationLink>
                      </PaginationItem>
                    ))}
                  </Pagination> */}{" "}
                  {renderPagination()}
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default SensorHistoryTable;
