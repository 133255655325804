import React, { useEffect, useState } from "react";
import ReactSwitch from "react-switch";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { Link } from "react-router-dom";
import { formatDateAndTime } from "../EMSUtils/utils";
function LogTable(props) {
  const [plantDropdownOpen, setPlantDropdownOpen] = useState(false);
  const [floorDropdownOpen, setFloorDropdownOpen] = useState(false);
  const [deviceDropdownOpen, setDeviceDropdownOpen] = useState(false);
  const [periodDropdownOpen, setPeriodDropdownOpen] = useState(false);

  const [plantDropdownValue, setPlantDropdownValue] = useState("Select Plant");
  const [floorDropdownValue, setFloorDropdownValue] = useState("Select Floor");

  const [maintenanceModes, setMaintenanceModes] = useState({});
  const sensorLogs = props.sensorLogs;

  const togglePlantDropdown = () =>
    setPlantDropdownOpen((prevState) => !prevState);
  const toggleFloorDropdown = () =>
    setFloorDropdownOpen((prevState) => !prevState);

  const handleMaintenanceMode = (index) => {
    setMaintenanceModes((prevModes) => ({
      ...prevModes,
      [index]: !prevModes[index],
    }));
  };

  // Function to format the timestamp into date and time
  const formattedDateAndTime = (timestamp) => {
    const date = new Date(timestamp);

    // Options to ensure 12-hour time format with AM/PM
    const timeOptions = {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true, // Ensure 12-hour format with AM or PM
      timeZone: "Asia/Kolkata", // Specify IST time zone
    };

    const dateOptions = {
      timeZone: "Asia/Kolkata", // Specify IST time zone
    };

    return {
      date: date.toLocaleDateString("en-IN", dateOptions), // Specify locale and time zone
      time: date.toLocaleTimeString("en-IN", timeOptions), // Use the specified locale and options
    };
  };

  useEffect(() => {
    console.log("Log Table");
    console.log(props);
  }, []);
  return (
    <>
      <Row className="align-items-center m-3">
        {/* <Col xl={6}>
          <Dropdown isOpen={plantDropdownOpen} toggle={togglePlantDropdown}>
            <DropdownToggle color="dark" style={{ width: "100%" }} caret>
              {plantDropdownValue}
            </DropdownToggle>
            <DropdownMenu style={{ width: "100%" }}>
              <DropdownItem onClick={() => setPlantDropdownValue("Plant 1")}>
                Plant 1
              </DropdownItem>
              {/* More options as needed */}
        {/* </DropdownMenu>
          </Dropdown>
        </Col> */}

        {/* <Col xl={6}>
          <Dropdown isOpen={floorDropdownOpen} toggle={toggleFloorDropdown}>
            <DropdownToggle color="dark" style={{ width: "100%" }} caret>
              {floorDropdownValue}
            </DropdownToggle>
            <DropdownMenu style={{ width: "100%" }}>
              <DropdownItem onClick={() => setFloorDropdownValue("Floor 1")}>
                Floor 1
              </DropdownItem>
              {/* More options as needed */}
        {/* </DropdownMenu>
          </Dropdown>
        </Col> */}
      </Row>

      <Row>
        <Col xl={12}>
          <Card>
            <CardHeader className="align-items-center d-flex bg-success">
              <h4 className="card-title mb-0 flex-grow-1 text-light">
                Sensor Data
              </h4>
              {/* <div className="flex-shrink-0">
                <UncontrolledDropdown className="card-header-dropdown">
                  <DropdownToggle
                    tag="a"
                    className="text-reset dropdown-btn"
                    role="button"
                  >
                    <span className="text-light">
                      02 Nov 2021 to 31 Dec 2021
                      <i className="mdi mdi-chevron-down ms-1"></i>
                    </span>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-end">
                    <DropdownItem>Today</DropdownItem>
                    <DropdownItem>Last Week</DropdownItem>
                    <DropdownItem>Last Month</DropdownItem>
                    <DropdownItem>Current Year</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div> */}
            </CardHeader>

            <CardBody>
              <div className="table-responsive table-card">
                <table className="table table-borderless table-hover table-nowrap align-middle mb-0">
                  <thead className="table-light">
                    <tr className="text-muted">
                      <th scope="col" style={{ width: "12%" }}>
                        Sr. No.
                      </th>
                      <th scope="col" style={{ width: "15%" }}>
                        Device ID
                      </th>
                      <th scope="col" style={{ width: "20%" }}>
                        Device Location
                      </th>
                      <th scope="col" style={{ width: "12%" }}>
                        Date
                      </th>
                      <th scope="col" style={{ width: "12%" }}>
                        Time
                      </th>
                      <th scope="col" style={{ width: "10%" }}>
                        Temp
                      </th>
                      <th scope="col" style={{ width: "10%" }}>
                        RH
                      </th>
                      <th scope="col" style={{ width: "10%" }}>
                        DP
                      </th>
                      <th scope="col" style={{ width: "16%" }}>
                        Alert Status
                      </th>
                      {/* <th scope="col" style={{ width: "15%" }}>
                        Maintenance Mode
                      </th> */}
                    </tr>
                  </thead>

                  <tbody>
                    {(sensorLogs || []).map((item, index) => (
                      <tr key={index}>
                        <td scope="col">{index + 1}</td>
                        <td scope="col">
                          <Link to={"/devices/" + item.device_id}>
                            {item.device_id}
                          </Link>
                        </td>
                        <td scope="col">
                          <div className="text-nowrap">{`${item.device.floor}/${item.device.room}`}</div>
                        </td>
                        <td scope="col">
                          <div className="text-nowrap">
                            {formattedDateAndTime(item.updatedAt).date}
                          </div>
                        </td>
                        <td scope="col">
                          <div className="text-nowrap">
                            {formattedDateAndTime(item.updatedAt).time}
                          </div>
                        </td>
                        <td scope="col">
                          <div className="text-nowrap">
                            {item.temperature.value} {item.temperature.unit}
                          </div>
                        </td>
                        <td>
                          <div className="text-nowrap">
                            {item.humidity.value} {item.humidity.unit}
                          </div>
                        </td>
                        <td>
                          <div className="text-nowrap">
                            {item.differential_pressure.value}{" "}
                            {item.differential_pressure.unit}
                          </div>
                        </td>
     					<td>
                          <span
                            className={
                              "badge bg-" +
                              (item.alert_status === 0
                                ? "success"
                                : item.alert_status === 1
                                ? "warning"
                                : "danger") +
                              "-subtle p-2 text-" +
                              (item.alert_status === 0
                                ? "success"
                                : item.alert_status === 1
                                ? "warning"
                                : "danger")
                            }
                          >
                            {item.alert_status === 0
                              ? "Normal"
                              : item.alert_status === 1
                              ? "Alert"
                              : "Alarm"}
                          </span>
                        </td>
                        {/* <td>
                          <div>
                            <ReactSwitch
                              checked={maintenanceModes[index]}
                              onChange={() => handleMaintenanceMode(index)}
                            />
                          </div>
                        </td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default LogTable;
