import React, { useState, useEffect } from "react";
import SensorTable from "../EMSDashboard/SensorTable";
import SensorCharts from "./SensorCharts";
import LogTable from "./LogTable";
import axios from "axios";
export default function SensorLogs() {
  // const api = new APIClient();
  const [sensorLogs, setSensorLogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    fetchData();
    const interval = setInterval(fetchData, 10000); // Fetch data every 30000 milliseconds (30 seconds)
    return () => clearInterval(interval);
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      setError(false);
      const response = await axios.get(
        "https://admin.skyware-ems.live/api/sensors/get-current-logs"
      );
      console.log("Dashboard:::::::::::::" + response.logs);
      setLoading(false);
      setSensorLogs(response.logs);
    } catch (error) {
      setError(true);
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  document.title = "Sensors | Skyware - EMS Dashboard";
  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0">Sensor Logs</h4>
              </div>
            </div>
          </div>
        </div>
        <LogTable sensorLogs={sensorLogs} />
        {/* <SensorCharts /> */}
      </div>
    </>
  );
}
